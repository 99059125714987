<template>
  <v-container>
    <h2 class="mb-1">Contact Supports</h2>
    <!-- <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p> -->
    <div>
      <h4>Available 24/7</h4>
      <div class="flex flex-row items-center mt-5 gap-x-5">
        <a href="tel:+2348156010101" class="text-center block">
          <img
            src="../../assets/img/contacts/outcoming-call.png"
            alt="call"
            class="w-7 h-7"
          />
          <p class="text-sm text-gray-700">Call</p>
        </a>
        <a href="emailto:help@snappyexchange.com" class="text-center block">
          <img
            src="../../assets/img/contacts/help-desk.png"
            alt="whatsapp"
            class="w-7 h-7"
          />
          <p class="text-sm text-gray-700">Support</p>
        </a>
      </div>
    </div>
    <div class="mt-8">
      <h4>Follow Snappy Exchange</h4>
      <div class="flex flex-row items-center mt-5 gap-x-5">
        <a
          href="https://web.facebook.com/@snappyexchange"
          class="text-center block"
        >
          <img
            src="../../assets/img/contacts/facebook.png"
            alt="whatsapp"
            class="w-7 h-7"
          />
          <p class="text-sm text-gray-700">Facebook</p>
        </a>
        <a
          href="https://www.instagram.com/snappyexchangeng/"
          class="text-center block"
        >
          <img
            src="../../assets/img/contacts/instagram.png"
            alt="whatsapp"
            class="w-7 h-7"
          />
          <p class="text-sm text-gray-700">Instagram</p>
        </a>
        <a href=" https://x.com/snappyexchange?mx=2" class="text-center block">
          <img
            src="../../assets/img/contacts/twitter.png"
            alt="whatsapp"
            class="w-7 h-7"
          />
          <p class="text-sm text-gray-700">X (Twitter)</p>
        </a>
        <a href="https://t.me/snappyexchange" class="text-center block">
          <img
            src="../../assets/img/contacts/telegram.png"
            alt="whatsapp"
            class="w-7 h-7"
          />
          <p class="text-sm text-gray-700">Telegram</p>
        </a>
        <a
          href="https://www.youtube.com/@snappyexchange"
          class="text-center block"
        >
          <img
            src="../../assets/img/contacts/youtube.png"
            alt="whatsapp"
            class="w-7 h-7"
          />
          <p class="text-sm text-gray-700">Youtube</p>
        </a>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      siteData: (state) => state.user.siteSettings
    })
  },
  methods: {
    doneLoading() {
      this.loading = false
    }
  }
}
</script>

<style></style>
